import React from 'react';
import { TopToolbar, Filter, List, Datagrid, TextField, Button, useRecordContext, useNotify, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import { supabaseClient as supabase } from '../../core/supabase';
import { Box } from '@mui/material';
import ActiveContractInput from '../../components/ActiveContractInput';
import { BikeListButton } from './BikeListButton';
import { CPExportButton } from './CPExportButton';


const CustomerListActions: React.FC = () => (
    <TopToolbar>
        {/* <CreateButton /> */}
        <BikeListButton /> {/* Add the BikeListButton here */}
        <CPExportButton />
    </TopToolbar>
);
/**
 * Filter component for InvoiceList, including the contract and status filters.
 */
const CustomerFilter: React.FC = (props) => (
    <Filter {...props}>
        <ActiveContractInput label="Room" source="contract_id" alwaysOn />
        <SelectInput
            label="Status"
            source="contract_status"
            choices={[
                { id: 'ACTIVE', name: 'Active' },
            ]}
            alwaysOn
        />
    </Filter>
);
export const CustomerList: React.FC = () => {
    return (
        <List
            filters={<CustomerFilter />}
            actions={<CustomerListActions />}
            resource='customer_room_view'
        >
            <Datagrid >
                <TextField source="customer_name" label="Customer Name" />
                <TextField source="phone_number" label="Phone Number" />
                <TextField source="bike_license_plate" label="Bike License Plate" />
                <TextField source="contract_status" label="Contract Status" />
            </Datagrid>
        </List>
    );
};

export default CustomerList;
