import React, { useState, useEffect, useRef } from 'react';
import {
    Show,
    SimpleShowLayout,
    useShowController,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,
    DateField,
    List,
} from 'react-admin';
import { Grid, Typography, CircularProgress, IconButton, Box, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PasteIcon from '@mui/icons-material/ContentPaste';
import { useDataProvider, useRefresh } from 'react-admin';
import { supabaseClient as supabase } from '../../core/supabase'; // Adjust path as needed
import ImageDisplay from '../../components/ImageDisplay';

const ExpenseShow: React.FC = (props) => {
    const { record, isLoading } = useShowController(props);
    const dataProvider = useDataProvider();
    const refresh = useRefresh();

    const [expenseItems, setExpenseItems] = useState<any[]>([]);
    const [loadingItems, setLoadingItems] = useState(true);

    const [paidProof, setPaidProof] = useState<File | null>(null);
    const [clipboardImage, setClipboardImage] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [error, setError] = useState<string>('');
    const [isMarkingPaid, setIsMarkingPaid] = useState(false);
    const [showPasteHint, setShowPasteHint] = useState(false);

    const [beneficiary, setBeneficiary] = useState<{ bank_account: string; bank_name: string } | null>(null);

    const [transferDescription, setTransferDescription] = useState<string>('');

    const pasteRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (record?.beneficiary_id) {
            dataProvider
                .getOne('beneficiaries', { id: record.beneficiary_id })
                .then(({ data }) => setBeneficiary(data))
                .catch((error) => {
                    console.error('Error fetching beneficiary details:', error);
                    setBeneficiary(null);
                });
        }
    }, [record?.beneficiary_id, dataProvider]);

    useEffect(() => {
        if (record?.id) {
            dataProvider
                .getList('expense_items', {
                    filter: { expense_id: record.id },
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'id', order: 'ASC' },
                })
                .then(({ data }) => {
                    setExpenseItems(data);
                    setLoadingItems(false);
                })
                .catch((error) => {
                    console.error('Error loading expense items:', error);
                    setLoadingItems(false);
                });
        }
    }, [record?.id, dataProvider]);


    useEffect(() => {
        const fetchContract = async (id) => {
            try {
                // Fetch active contracts
                const { data: contract, error } = await supabase
                    .from('contracts')
                    .select('id, rooms(room_name)')
                    .eq('id', id)
                    .single();


                if (error) {
                    console.error('Error fetching active contracts:', error);
                    return;
                }
                let roomName = contract.rooms.room_name;
                let transferDescription = beneficiary.bank_account === '19038295382668' ? 'NGUYEN VAN HAO WT272930WL CHO VAY' : ("52 TBT " + roomName).trim(); // Placeholder function
                setTransferDescription(transferDescription);
            } catch (err) {
                console.error('Unexpected error fetching contracts:', err);
            }
        };


        if (record?.contract_id && !!beneficiary) {
            fetchContract(record?.contract_id);
        }

    }, [beneficiary, record?.contract_id, dataProvider]);



    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setPaidProof(file);
            setError('');
            showImagePreview(file);
        }
    };

    const handlePaste = (event: React.ClipboardEvent) => {
        const items = event.clipboardData.items;
        for (const item of items) {
            if (item.type.includes('image')) {
                const file = item.getAsFile();
                if (file) {
                    setClipboardImage(file);
                    setError('');
                    showImagePreview(file);
                }
            }
        }
    };

    const showImagePreview = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e) => setImagePreview(e.target?.result as string);
        reader.readAsDataURL(file);
    };

    const handleConfirmPaid = async () => {
        const imageFile = clipboardImage || paidProof;
        if (!imageFile) {
            setError('Please provide a proof of payment.');
            return;
        }

        setIsMarkingPaid(true);
        setError('');

        try {
            const fileName = `${record.id}-${new Date().getTime()}`;
            const path = `payment_proofs/${fileName}`;
            const { error: uploadError } = await supabase.storage
                .from('tigon')
                .upload(path, imageFile);

            if (uploadError) {
                setError('Error uploading image. Please try again.');
                setIsMarkingPaid(false);
                return;
            }

            await dataProvider.update('expenses', {
                id: record.id,
                data: { status: 'PAID', paid_proof_url: path },
                previousData: record,
            });

            refresh();
        } catch (err) {
            setError('Error processing payment. Please try again.');
        } finally {
            setIsMarkingPaid(false);
        }
    };

    if (isLoading || loadingItems) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '50vh' }}>
                <CircularProgress />
            </Grid>
        );
    }
    const handlePasteClick = () => {
        pasteRef.current?.focus();
        setShowPasteHint(true);
    };

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <Grid container spacing={2}>
                    {/* Display Expense Details */}
                    <Grid item xs={6} md={4}>
                        <Typography variant="body2" color="textSecondary">Expense Date</Typography>
                        <DateField source="expense_date" />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography variant="body2" color="textSecondary">Total Amount</Typography>
                        <NumberField source="amount" options={{ useGrouping: true, maximumFractionDigits: 0 }} />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography variant="body2" color="textSecondary">Description</Typography>
                        <TextField source="description" />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography variant="body2" color="textSecondary">Beneficiary</Typography>
                        <ReferenceField source="beneficiary_id" reference="beneficiaries">
                            <TextField source="beneficiary_name" />
                        </ReferenceField>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography variant="body2" color="textSecondary">Status</Typography>
                        <TextField source="status" />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography variant="body2" color="textSecondary">Room</Typography>
                        <ReferenceField label="Room" source="contract_id" reference="contracts" link={false}>
                            <ReferenceField source="room_id" reference="rooms">
                                <TextField source="room_name" />
                            </ReferenceField>
                        </ReferenceField>
                    </Grid>
                    {/* QR Code Section */}
                    {record.status === 'PENDING' && (
                        <Grid item xs={12}>
                            {beneficiary && beneficiary.bank_account && beneficiary.bank_name ? (
                                <>
                                    <Typography variant="h6">QR Code for Payment</Typography>
                                    {/* 
                                    https://img.vietqr.io/image/${beneficiary.bank_name}-${beneficiary.bank_account}-compact.png?amount=${record.amount}&addInfo=${generateQrDescription()} 
                                    src={`https://qr.sepay.vn/img?acc=${beneficiary.bank_account}&bank=${beneficiary.bank_name}&amount=${record.amount}&des=${generateQrDescription()}`}
                                    */}
                                    <Box
                                        component="img"
                                        src={`https://img.vietqr.io/image/${beneficiary.bank_name}-${beneficiary.bank_account}-compact.png?amount=${record.amount}&addInfo=${transferDescription}`}
                                        alt="QR Code"
                                        sx={{ maxWidth: '100%', maxHeight: 200 }}
                                    />
                                </>
                            ) : (
                                <Typography color="error" variant="subtitle1">
                                    Unable to generate QR Code. Please ensure the beneficiary has valid bank account and bank name details.
                                </Typography>
                            )}
                        </Grid>
                    )}

                    {/* Proof of Payment Section */}
                    {record.status === 'PENDING' && (
                        <Grid item xs={12}>
                            <Typography variant="h6">Provide Proof of Payment</Typography>
                            <Box display="flex" alignItems="center" gap={2} onPaste={handlePaste}>
                                <IconButton component="label" color="primary">
                                    <CloudUploadIcon />
                                    <input hidden type="file" accept="image/*" onChange={handleFileChange} />
                                </IconButton>
                                {/* Paste Handler */}
                                <IconButton color="primary" onClick={handlePasteClick}>
                                    <PasteIcon />
                                </IconButton>

                                {/* Hidden Textarea for Paste Handling */}
                                <textarea
                                    ref={pasteRef}
                                    style={{ position: 'absolute', opacity: 0, pointerEvents: 'none' }}
                                    onPaste={handlePaste}
                                />
                            </Box>
                            {showPasteHint && (
                                <Typography variant="body2" color="textSecondary" mt={1}>
                                    Press Ctrl+V to paste the image.
                                </Typography>
                            )}
                            {imagePreview && (
                                <Box mt={2}>
                                    <Typography variant="subtitle1">Preview:</Typography>
                                    <img
                                        src={imagePreview}
                                        alt="Payment proof"
                                        style={{ width: '100%', maxHeight: 300, objectFit: 'contain' }}
                                    />
                                </Box>
                            )}
                            {error && <Typography color="error">{error}</Typography>}
                            <Box mt={2}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleConfirmPaid}
                                    disabled={isMarkingPaid}
                                    startIcon={isMarkingPaid ? <CircularProgress size={20} /> : null}
                                >
                                    {isMarkingPaid ? 'Processing...' : 'Mark as Paid'}
                                </Button>
                            </Box>
                        </Grid>
                    )}

                    {/* Display Expense Items in a Datagrid */}
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            Expense Items
                        </Typography>
                        <Datagrid
                            data={expenseItems}
                            rowClick={false}
                            bulkActionButtons={false}
                            empty={<div>No expenses found</div>}
                        >
                            <TextField source="item_name" label="Item Name" />
                            <NumberField source="amount" label="Amount" options={{ useGrouping: true, maximumFractionDigits: 0 }} />
                            <TextField source="category" label="Category" />
                        </Datagrid>

                    </Grid>
                </Grid>

                {record.paid_proof_url && <ImageDisplay
                    source="paid_proof_url"
                    prefix="meters"
                />}
            </SimpleShowLayout>
        </Show>
    );
};

export default ExpenseShow;
