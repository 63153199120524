import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { Button, useRecordContext, useNotify } from 'react-admin';
import { supabaseClient as supabase } from "../../../core/supabase";
import dayjs from 'dayjs';
import numberToVietnameseText from '../n2vi';
import LocalPolice from '@mui/icons-material/LocalPolice';
/**
 * Rounds a number up to the nearest thousand.
 *
 * @param {number} num - The number to round up.
 * @returns {number} - The rounded number.
 */
const roundUpToThousand = (num) => {
    return Math.ceil(num / 1000) * 1000;
};
const PrintCPButton: React.FC = () => {
    const notify = useNotify();
    const record = useRecordContext(); // Access the current record for display
    const [loading, setLoading] = useState(false);

    if (!record) return null; // If no record is loaded, do not display button

    const handlePrint = async () => {
        setLoading(true);

        try {
            // Load HTML template from Supabase storage
            const { data: templateFile, error: downloadError } = await supabase
                .storage
                .from('tigon')
                .download('template/hopdongkekhai.htm');

            if (downloadError) {
                throw new Error('Error downloading template: ' + downloadError.message);
            }

            // Read template content as text
            const templateContent = await templateFile.text();

            // Helper functions
            const formatDate = (date: string | Date) => (date ? dayjs(date).format('ngày DD [tháng] MM [năm] YYYY') : 'N/A');
            const extractYear = (date) => date ? dayjs(date).year() : 'N/A';
            const extractMonth = (date) => date ? dayjs(date).month() + 1 : 'N/A';
            const formatDuration = (term: string) =>
                term
                    .replaceAll('months', 'tháng')
                    .replaceAll('month', 'tháng')
                    .replaceAll('years', 'năm')
                    .replaceAll('year', 'năm');

            const primaryCustomer = record.contract_customers[0];
            const rentFee = roundUpToThousand(0.7 * record.contract_service_fees.find(f => f.fee_type === 'RENT')?.negotiated_fee);
            const deposit_amount = roundUpToThousand(0.7 * record.deposit_amount);

            const placeholderMapping = {
                '#cccdbenb#': primaryCustomer?.customer_identity_number || 'N/A',
                '#giathue#': rentFee || 'N/A',
                '#giathuebangchu#': numberToVietnameseText(rentFee),
                '#hotenbenb#': primaryCustomer?.customer_name || 'N/A',
                '#namhopdong#': extractYear(record.start_date),
                '#namsinhbenb#': extractYear(primaryCustomer?.customer_dob),
                '#ngaycapbenb#': formatDate(primaryCustomer?.customer_id_issue_date),
                '#ngayhopdong#': formatDate(record.start_date),
                '#ngaybatdauhopdong#': formatDate(record.start_date),
                '#sothang#': formatDuration(record.contract_duration || 'N/A'),
                '#thanghopdong#': extractMonth(record.start_date),
                '#tiencoc#': deposit_amount || 'N/A',
                '#tiencocbangchu#': numberToVietnameseText(deposit_amount),
            };


            // Replace placeholders in the HTML template
            let finalHtml = templateContent;
            Object.entries(placeholderMapping).forEach(([placeholder, value]) => {
                finalHtml = finalHtml.replaceAll(placeholder, value as string);
            });

            // Open final HTML in a new tab
            const blob = new Blob([finalHtml], { type: 'text/html' });
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error during processing:', error.message);
            notify('Error processing print data', { type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            onClick={handlePrint}
            disabled={loading}
            startIcon={loading ? <CircularProgress size={20} /> : <LocalPolice />}
            label={loading ? 'Processing...' : 'HĐ Kê Khai'}
        >

        </Button>
    );
};

export default PrintCPButton;
