import { utils, write as XLSXwrite } from 'xlsx';
import React, { useCallback, useState } from 'react';
import { TopToolbar, useDataProvider, useNotify } from 'react-admin';
import { Button, LinearProgress, Box } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { supabaseClient as supabase } from '../../core/supabase';
import LocalPolice from '@mui/icons-material/LocalPolice';

const guessMimeType = (imagePath: string): string => {
    const extension = imagePath.split('.').pop()?.toLowerCase();
    switch (extension) {
        case 'jpg':
        case 'jpeg':
            return 'image/jpeg';
        case 'png':
            return 'image/png';
        case 'gif':
            return 'image/gif';
        case 'bmp':
            return 'image/bmp';
        case 'webp':
            return 'image/webp';
        default:
            return 'image/jpeg';
    }
};

export const CPExportButton = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [progress, setProgress] = useState<number | null>(null);

    const handleClick = useCallback(async () => {
        setProgress(0);
        try {
            // 1. Fetch data from view_active_customers
            const { data } = await dataProvider.getList('view_active_customers', {
                pagination: { page: 1, perPage: 10000 }, // adjust as needed or paginate through all records
                sort: { field: 'customer_name', order: 'ASC' },
                filter: {}
            });

            // 2. Sort data by first name
            const sortedData = [...data].sort((a: any, b: any) => {
                const aFirstName = a.customer_name ? a.customer_name.split(' ').pop().toLowerCase() : '';
                const bFirstName = b.customer_name ? b.customer_name.split(' ').pop().toLowerCase() : '';
                return aFirstName.localeCompare(bFirstName);
            });

            // Define the columns you want in the Excel file
            // Adjust headers and fields as needed. The example below uses Vietnamese headers.
            const headers = ['Họ tên', 'SĐT', 'CCCD', 'Ngày'];

            // Convert sorted data into an array of arrays
            const wsData = [headers];
            for (const record of sortedData) {
                wsData.push([
                    record.customer_name || '',
                    record.phone_number || '',
                    record.customer_identity_number || '',
                    record.customer_dob?.split('T')[0] || ''
                ]);
            }

            // 3. Create an Excel workbook and worksheet
            const wb = utils.book_new();
            const ws = utils.aoa_to_sheet(wsData);
            utils.book_append_sheet(wb, ws, 'Người ở lại');
            ws["!cols"] = [
                { wch: 30 },
                { wch: 12 },
                { wch: 16 },
                { wch: 12 },
            ]; // set column A width to 10 characters

            // 4. Write the workbook to binary
            const wbout = XLSXwrite(wb, { bookType: 'xlsx', type: 'array' });

            // Create a Blob from the array buffer
            const blob = new Blob([wbout], { type: "application/octet-stream" });

            // Use file-saver to prompt the download
            saveAs(blob, 'customers.xlsx');
            notify('Download complete', { type: 'info' });
            return;
            // 5. Create a zip file and add the Excel file
            const zip = new JSZip();
            zip.file('danh_sach.xlsx', wbout, { binary: true });


            // 4. For each record, download images and add them to the zip
            // We'll update progress as we go along.
            for (let i = 0; false && i < data.length; i++) {
                const record = data[i];

                const firstName = record.customer_name ? record.customer_name.split(' ').pop() : 'unknown';
                const lastName = record.customer_name ? record.customer_name.split(' ')[0] : 'unknown';
                const dob = record.customer_dob ? record.customer_dob.split('T')[0] : 'na';

                // Front image
                if (record.id_card_front_url) {
                    const frontPath = record.id_card_front_url;
                    const frontExtension = frontPath.split('.').pop();
                    const frontUniqueId = crypto.randomUUID().split('-')[0];
                    const frontFileName = `${firstName}_${lastName}_${dob}_truoc_${frontUniqueId}.${frontExtension}`;

                    const { data: frontData, error: frontError } = await supabase.storage.from('tigon').download(frontPath);
                    if (frontError) {
                        throw new Error('Error fetching front image: ' + frontError.message);
                    }
                    const frontMimeType = guessMimeType(frontPath);
                    const frontBlob = frontData.slice(0, frontData.size, frontMimeType);
                    const frontArrayBuffer = await frontBlob.arrayBuffer();
                    zip.file(frontFileName, frontArrayBuffer);
                }

                // Back image
                if (record.id_card_back_url) {
                    const backPath = record.id_card_back_url;
                    const backExtension = backPath.split('.').pop();
                    const backUniqueId = crypto.randomUUID().split('-')[0];
                    const backFileName = `${firstName}_${lastName}_${dob}_sau_${backUniqueId}.${backExtension}`;

                    const { data: backData, error: backError } = await supabase.storage.from('tigon').download(backPath);
                    if (backError) {
                        throw new Error('Error fetching back image: ' + backError.message);
                    }
                    const backMimeType = guessMimeType(backPath);
                    const backBlob = backData.slice(0, backData.size, backMimeType);
                    const backArrayBuffer = await backBlob.arrayBuffer();
                    zip.file(backFileName, backArrayBuffer);
                }

                // Update progress: out of total records processed
                setProgress(Math.round(((i + 1) / data.length) * 100));
            }

            // 5. Generate the zip file
            const zipBlob = await zip.generateAsync({ type: 'blob' });
            saveAs(zipBlob, 'active_customers.zip');

            notify('Download complete', { type: 'info' });
        } catch (error: any) {
            console.error(error);
            notify(`Error: ${error.message}`, { type: 'warning' });
        } finally {
            setProgress(null);
        }
    }, [dataProvider, notify]);

    return (
        <Box>
            <Button
                startIcon={<LocalPolice />}
                // variant="contained"
                // color="primary"
                onClick={handleClick}
                disabled={progress !== null}
            >
                Export CP
            </Button>
            {progress !== null && (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
            )}
        </Box>
    );
};
